export default [
  {
    title: 'Dashboard',
    route: 'dashboard-analytics',
    icon: 'HomeIcon',
  },
  {
    title: '컨텐츠 관리',
    icon: 'CastIcon',
    children: [
      {
        title: '모아보기 배너관리',
        route: 'apps-moabanner-manage',
      },
      {
        title: '삽입 배너관리',
        route: 'apps-insert-banner-manage',
      },
      {
        title: '약품정보',
        route: 'apps-druginfo-manage',
      },
      {
        title: '간편처방등록',
        route: 'apps-druglanding-manage',
      },
      {
        title: '메디컬트렌드',
        route: 'apps-medicaltrend-manage',
      },
      {
        title: '설문조사',
        route: 'apps-survey-manage',
      },
      {
        title: '설문지 생성',
        route: 'apps-survey-paper-list',
      },
      {
        title: '설문조사 기프트 교환',
        route: 'apps-survey-gift-manage',
      },
      {
        title: 'Weekly Choice',
        route: 'apps-poll-manage',
      },
      {
        title: '웹 심포지엄',
        route: 'apps-webseminar-manage',
      },
      {
        title: '이벤트',
        route: 'apps-event-manage',
      },
      {
        title: '이벤트 기프트 교환',
        route: 'apps-event-gift-manage',
      },
      {
        title: '공지사항',
        route: 'apps-notice-manage',
      },
      {
        title: '투표형 댓글 관리',
        route: 'apps-comment-survey-manage',
      },
      {
        title: '이미지 리소스 관리',
        route: 'apps-image-resource-manage',
      },
    ],
  },
  {
    title: '회원 관리',
    icon: 'UsersIcon',
    children: [
      {
        title: '제약사 관리',
        route: 'apps-client-manage',
      },
      {
        title: '고객 관리',
        route: 'apps-member-manage',
      },
      {
        title: '탈퇴 관리',
        route: 'apps-withdraw-manage',
      },
      {
        title: '비 고객 관리',
        route: 'apps-non-member-manage',
      },
      {
        title: '1:1 문의 관리',
        route: 'apps-member-ask-history',
      },
      {
        title: 'Call 문의 관리',
        route: 'apps-member-call-history',
      },
      // 인프라팀 보안 조치 요구사항으로 사용하지 않는 메뉴 숨김 처리
      // {
      //   title: 'Poll 제안 관리',
      //   route: 'apps-poll-suggest',
      // },
      {
        title: '댓글 관리',
        route: 'apps-comment-manage',
      },
    ],
  },
  {
    title: '통계',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'On/Off 현황',
        route: 'apps-statistics-onoff',
      },
      {
        title: '모아보기 창 현황',
        route: 'apps-statistics-moa-call',
      },
      {
        title: '약품정보',
        route: 'apps-statistics-drug-info',
      },
      {
        title: '라이브 DI',
        route: 'apps-statistics-live-di',
      },
      {
        title: '간편처방등록',
        route: 'apps-statistics-drug-landing',
      },
      {
        title: '메디컬트렌드',
        route: 'apps-statistics-medical-trend',
      },
      {
        title: '설문조사',
        route: 'apps-statistics-survey',
      },
      {
        title: 'Weekly Choice',
        route: 'apps-statistics-weekly-choice',
      },
      {
        title: '웹 심포지엄',
        route: 'apps-statistics-webseminar',
      },
      {
        title: '이벤트',
        route: 'apps-statistics-event',
      },
      {
        title: '가입/탈퇴 현황',
        route: 'apps-statistics-sign-draw',
      },
      {
        title: '방문자 유입 현황',
        route: 'apps-statistics-entry',
      },
      {
        title: '메뉴 조회 현황',
        route: 'apps-statistics-menu-entry',
      },
      {
        title: '컨텐츠 조회 현황',
        route: 'apps-statistics-content-join',
      },
      {
        title: '포인트 참여 현황',
        route: 'apps-statistics-user-join',
      },
    ],
  },
  // 인프라팀 보안 조치 요구사항으로 사용하지 않는 메뉴 숨김 처리
  // {
  //   title: '신청 관리',
  //   icon: 'EditIcon',
  //   children: [
  //     {
  //       title: '방문 신청 관리',
  //       route: 'apps-visit-ask-manage',
  //     },
  //     {
  //       title: '샘플 신청 관리',
  //       route: 'apps-sample-ask-manage',
  //     },
  //     {
  //       title: '이메일 문의 관리',
  //       route: 'apps-email-ask-manage',
  //     },
  //   ],
  // },
  {
    title: '포인트 관리',
    route: 'd',
    icon: 'GiftIcon',
    children: [
      {
        title: '적립/사용 통계',
        route: 'apps-point-history-manage',
      },
      {
        title: '포인트 제도 관리',
        route: 'apps-point-system-manage',
      },
      {
        title: '포인트 수여/회수',
        route: 'apps-point-award-manage',
      },
      {
        title: '포인트 기프트 교환',
        route: 'apps-point-gift-manage',
      },
      {
        title: '기프티콘 발송 내역 관리',
        route: 'apps-gift-order-manage',
      },
    ],
  },
  {
    title: '기타',
    icon: 'GridIcon',
    children: [
      {
        title: '약관 관리',
        route: 'apps-terms-manage',
      },
      {
        title: '관리자 권한 관리',
        route: 'apps-manager-auth-manage',
      },
    ],
  },
]
