import { render, staticRenderFns } from "./VerticalNavMenuLink.vue?vue&type=template&id=d8f851d8&"
import script from "./VerticalNavMenuLink.vue?vue&type=script&lang=js&"
export * from "./VerticalNavMenuLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Bitnami\\jenkins-2.89.4-1\\apps\\jenkins\\jenkins_home\\jobs\\liveinfo3-front-admin-dev\\workspace\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d8f851d8')) {
      api.createRecord('d8f851d8', component.options)
    } else {
      api.reload('d8f851d8', component.options)
    }
    module.hot.accept("./VerticalNavMenuLink.vue?vue&type=template&id=d8f851d8&", function () {
      api.rerender('d8f851d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-link/VerticalNavMenuLink.vue"
export default component.exports